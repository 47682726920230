@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'TTNormsBold';
    src: local('TTNormsBold'), url(./fonts/TTNorms-Bold.otf) format('woff');
}

@font-face {
    font-family: 'TTNormsMedium';
    src: local('TTNormsMedium'), url(./fonts/TTNorms-Medium.otf) format('woff');
}

@font-face {
    font-family: "TTNormsRegular";
    src: local('TTNormsRegular'), url(./fonts/TTNorms-Regular.otf) format('woff');
}

/** Global Css Variables **/
:root {
    --default: #AFD9D5;
    --black: #000000;
    --black1: #3A3A3A;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "TTNormsRegular", sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: #00000000;
    -webkit-tap-highlight-color: #0000;
}

/*===== Root =====*/
#root {
    background-color: var(--silver);
    max-width: 1600px;
}

body {
    background-color: var(--default);
    position: relative;
}

.es-bold-font {
    font-family: "TTNormsBold";
}

.es-bold-font {
    font-family: "TTNormsMedium";
}

.es-grow {
    width: 350px;
    height: 350px;
}

.custom-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: var(--default);
}

.nav-menu {
    display: flex;
    height: 0%;
    overflow: hidden;
    visibility: hidden;
}

.nav-menu.show {
    transition: all 200ms ease-in-out;
    width: 100%;
    position: fixed;
    top: 3rem;
    left: 0;
    padding: 4px 32px;
    z-index: 1;
    background-color: white;
    height: 80%;
    visibility: visible;
    transition-timing-function: linear;
}

.fix-later {
    display: none;
}

.nav-menu.show ul {
    padding: 3rem 1rem;
}

.nav-menu.show li {
    font-family: TTNormsMedium;
    margin-bottom: 2rem;
    margin-left: 2rem;
    color: #000;
    font-weight: bold;
}

.custom-nav .logo {}

.custom-nav .nav-menu {}

@media (min-width: 768px) {

    .menu-btn,
    .hide-btn {
        display: none;
    }

    .fix-later {
        display: inline-block;
    }

    header.custom-header {
        height: 5rem;
        position: relative;
        display: grid;
        grid-template-columns: 30% 70%;
    }

    .nav-menu {
        position: relative;
        visibility: visible;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .nav-menu ul {
        display: inline-flex;
        align-items: center;
    }

    .nav-menu li {
        margin-right: 1rem;
        color: #fff;
        font-weight: medium;
    }

    .btn-connect {
        background-color: #fff;
        color: var(--default);
    }
}

@media (min-width: 820px) {
    .es-grow {
        width: 550px;
        height: 550px;
    }
}

@media screen and (min-width: 912px) {
    body {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .es-grow {
        width: 610px;
        height: 610px;
    }

    .es-container {
        max-width: 1250px;
        width: 1250px;
    }
}
.column {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }


  #a{
    display: flex;
    justify-content: right;
  }
p.a{
    text-size-adjust: 3px;
}